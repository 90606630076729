<script lang="ts" setup>
defineProps({
  src: { type: String, required: true },
  alt: { type: String, required: true },
})

const error = ref(false)

const onError = () => {
  error.value = true
}
</script>

<template>
  <div class="md:aspect-[4/1] aspect-[3/1] rounded-2.5xl border border-zeno-gray-border-light bg-zeno-gray-light -pl-6">
    <NuxtImg
      v-if="!error"
      :alt="alt"
      :src="src"
      class="w-full object-center object-cover md:aspect-[4/1] aspect-[3/1] rounded-2.5xl"
      @error="onError"
    />
  </div>
</template>

<style scoped></style>
